<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="nomConexion"
                label="Nombre de conexión"
                :rules="rules.required"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="datoConexion"
                :rules="rules.required"
                label="IP o url de conexión"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="puerto"
                label="Puerto"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="dominio"
                label="Dominio"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="usuario"
                :rules="rules.required"
                label="Usuario"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="py-0">
              <v-text-field
                tabindex="2"
                outlined
                dense
                onkeydown="return (event.keyCode !== 32)"
                label="Contraseña (*)"
                :append-icon="
                  !contrasenia
                    ? ''
                    : passwordTextType
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                @click:append="() => (passwordTextType = !passwordTextType)"
                :type="passwordTextType ? 'password' : 'text'"
                :rules="
                  rules.required.concat([rules.maxLength(contrasenia, 100)])
                "
                autocomplete="new-password"
                v-model.trim="contrasenia"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de texto -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                tabindex="2"
                v-model="observaciones"
                label="Observaciones"
                dense
                auto-grow
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-switch
                class="py-0"
                v-model="habilitado"
                label="Habilitado"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
export default {
  name: "EditConexionRdp",
  props: {
    registrosParams: {
      Type: Object,
      required: false,
    },
    idReg: {
      Type: Number,
    },
  },
  data: () => ({
    usuario: null,
    datoConexion: null,
    nomConexion: null,
    contrasenia: null,
    puerto: "",
    dominio: "",
    formEditTitle: "",
    rules: rules,
    passwordTextType: String,
    habilitado: true,
    isFormValid: false,
    usuarioSelected: null,
    observaciones: null,
    loadingSaveBtn: false,
  }),
  created() {
    this.registrosParams;
    if (this.registrosParams) {
      this.setConexionRdp();
    } else {
      this.newConexionRdp();
    }
  },
  methods: {
    ...mapActions({
      saveConexion: "clientesData/saveConexion",
      setAlert: "user/setAlert",
    }),
    newConexionRdp() {
      this.formEditTitle = "Nueva conexión vía RDP";
    },
    async setConexionRdp() {
      this.formEditTitle = "Editar conexión vía RDP";
      this.nomConexion = this.registrosParams.nombreConexion;
      this.datoConexion = this.registrosParams.datoIp;
      this.usuario = this.registrosParams.usuario;
      this.puerto = this.registrosParams.puerto;
      this.dominio = this.registrosParams.dominio;
      this.contrasenia = this.registrosParams.contrasenia;
      this.observaciones = this.registrosParams.observaciones;
      this.habilitado = this.registrosParams.habilitado;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        id:
          this.registrosParams?.id != null
            ? parseInt(this.registrosParams.id)
            : null,
        clienteId: parseInt(this.idReg),
        nombreConexion: this.nomConexion,
        nombreIp: this.datoConexion,
        usuario: this.usuario,
        habilitado: this.habilitado,
        contrasenia: this.contrasenia,
        dominio: this.dominio,
        puerto: this.puerto,
        observaciones: this.observaciones ? this.observaciones : null,
      };
      try {
        const response = await this.saveConexion(data);
        if (response.status === 200) {
          this.loadingSaveBtn = false;
          this.closeModal();
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }
      } catch (error) {
        if (this.registrosParams) {
          this.loadingSaveBtn = false;
        } else {
          this.loadingSaveBtn = false;
          this.nomConexion = null;
          this.datoConexion = null;
          this.usuario = null;
          this.contrasenia = null;
          this.puerto;
          this.dominio;
          this.observaciones = null;
          this.habilitado = false;
        }
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped>
/* ::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
} */

/* ::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56, 1);
} */
</style>
