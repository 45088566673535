<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="registrosUsuariosConectados"
          class="elevation-1"
          :loading="isLoading"
          :page.sync="pagination"
        >
        </v-data-table>
      </v-card>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Conectar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
export default {
  name: "ConectEscRemoto",
  props: {
    registrosParams: {
      Type: Object,
      required: false,
    },
    idReg: {
      Type: Number,
    },
  },
  data: () => ({
    usuario: null,
    datoConexion: null,
    registrosUsuariosConectados: [],
    nomConexion: null,
    contrasenia: null,
    formEditTitle: "Usuario conectado",
    rules: rules,
    habilitado: true,
    pagination: null,
    isFormValid: false,
    usuarioSelected: null,
    observaciones: null,
    loadingSaveBtn: false,
    isLoading: false,
    headers: [
      {
        text: "Usuario conectado",
        value: "codigoDesde",
        sortable: false,
      },
    ],
  }),
  created() {
    // this.registrosParams
    // console.log("🚀 ~ file: EditConexionRdp.vue:129 ~ created ~ this.registrosParams:", this.registrosParams)
    // if (this.registrosParams) {
    //   this.setConexionRdp();
    // } else {
    //   this.newConexionRdp();
    // }
  },
  methods: {
    ...mapActions({
      // saveConexion: "clientesData/saveConexion",
      setAlert: "user/setAlert",
    }),
    // async setConexionRdp() {
    //   this.nomConexion = this.registrosParams.nombreConexion;
    //   this.datoConexion = this.registrosParams.datoIp;
    //   this.usuario = this.registrosParams.usuario;
    //   this.contrasenia = this.registrosParams.contrasenia;
    //   this.observaciones = this.registrosParams.observaciones;
    //   this.habilitado = this.registrosParams.habilitado;
    // },
    // async saveEdit() {
    //   this.loadingSaveBtn = true;
    //   const data = {
    //     id: this.registrosParams?.id != null ? parseInt(this.registrosParams.id) : null,
    //     clienteId: parseInt(this.idReg),
    //     nombreConexion: this.nomConexion,
    //     nombreIp: this.datoConexion,
    //     usuario: this.usuario,
    //     habilitado: this.habilitado,
    //     contrasenia: this.contrasenia,
    //     observaciones: this.observaciones?this.observaciones: null,
    //   };
    //   try {
    //     const response = await this.saveConexion(data);
    //     if (response.status === 200) {
    //       this.loadingSaveBtn = false;
    //       this.closeModal();
    //       this.setAlert({ type: "success", message: "Guardado con éxito." });
    //     }
    //   } catch (error) {
    //     if (this.registrosParams) {
    //       this.loadingSaveBtn = false;
    //     } else {
    //       this.loadingSaveBtn = false;
    //       this.nomConexion = null;
    //       this.datoConexion = null;
    //       this.usuario = null;
    //       this.contrasenia = null;
    //       this.observaciones = null;
    //       this.habilitado = false;
    //     }
    //   }
    // },
    closeModal() {
      this.$emit("closeAndReloadConect");
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
