<template>
  <v-container>
    <v-row>
      <v-col>
        <go-back-btn :routeToGo="routeToGo" />
        <PageHeader :title="`${title} ${this.nomCliente}`" class="mb-3" />
        <!-- Data table -->
        <v-card>
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="itemsDatosConexion"
            :loading="loading"
            item-key="idDatos"
            no-data-text="No hay datos disponibles para mostrar"
            :search="searchConsulta"
          >
            <template v-slot:[`item.datosTexto`]="{ item }">
              <tr
                v-for="(line, index) in item.datosTexto.split('\n')"
                :key="index"
              >
                <td>{{ line }}</td>
              </tr>
            </template>
            <template v-slot:[`item.habilitado`]="{ item }">
              <v-icon
                v-if="item.habilitado"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10" align-self="center" class="pt-5">
                    <v-text-field
                      v-model="searchConsulta"
                      clearable
                      label="Buscar"
                      single-line
                      :append-icon="iconoSearch"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    align="end"
                    class="pt-5"
                    v-if="canCreateConexion"
                  >
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEditConexion">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left v-if="canDeleteConexion">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteConec(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
              <v-tooltip left v-if="item.usuarioConectado">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" size="small" v-on="on" color="success">
                    {{ active }}
                  </v-icon>
                </template>
                <span>Usuario: {{ item.usuarioConectado }} conectado.</span>
              </v-tooltip>
              <v-tooltip left v-else>
                <template v-slot:activator="{ attrs }">
                  <v-icon v-bind="attrs" size="small" color="grey">
                    {{ active }}
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalUsers"
      v-model="openModalUsers"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditConexionRdp
        :idReg="idReg"
        :recibeId="recibeId"
        :itemsAccesoDatos="itemsAccesoDatos"
        :registrosParams="registrosParams"
        :regClientesId="regClientesId"
        @closeAndReload="closeAndReload"
      ></EditConexionRdp>
    </v-dialog>
    <v-dialog
      v-if="openModalConec"
      v-model="openModalConec"
      max-width="45%"
      @keydown.esc="closeAndReloadConect"
      persistent
    >
      <ConectEscRemoto
        :idReg="idReg"
        :recibeId="recibeId"
        :itemsAccesoDatos="itemsAccesoDatos"
        :registrosParams="registrosParams"
        :regClientesId="regClientesId"
        @closeAndReloadConect="closeAndReloadConect"
      ></ConectEscRemoto>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'28%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditConexionRdp from "@/views/EditConexionRdp.vue";
import ConectEscRemoto from "@/views/ConectEscRemoto.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    EditConexionRdp,
    ConectEscRemoto,
    DeleteDialog,
  },
  name: "ConexionRdp",
  data() {
    return {
      titleDelete: "¿Eliminar datos de conexión?",
      showDeleteModal: false,
      isFormValid: false,
      loading: false,
      routeToGo: "/clientes",
      checkIcon: enums.icons.CHECK_OUTLINE,
      editIcon: enums.icons.EDIT,
      active: enums.icons.ICON_ACTIVE,
      deleteIcon: enums.icons.DELETE,
      rules: rules,
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      openModalUsers: false,
      itemsAccesoDatos: [],
      regClientesId: null,
      itemsDatosConexion: [],
      advancedUsers: false,
      title: "Gestión de conexiones RDP: ",
      idToDelete: null,
      recibeId: null,
      registrosParams: {},
      idReg: null,
      nomCliente: "",
      allowedActions: null,
      canCreateConexion: false,
      canEditConexion: false,
      openModalConec: false,
      canDeleteConexion: false,
      canConectar: false,
      canVerConectados: false,
      usuarioConectado: null,
      headers: [
        {
          text: "Conexión",
          sortable: false,
          value: "nombreConexion",
        },
        {
          text: "IP o url",
          sortable: false,
          value: "datoIp",
        },
        {
          text: "Usuario",
          sortable: false,
          value: "usuario",
        },
        {
          text: "Dominio",
          sortable: false,
          value: "dominio",
        },
        {
          text: "Puerto",
          sortable: false,
          value: "puerto",
        },
        {
          text: "Activo",
          align: "center",
          sortable: false,
          value: "habilitado",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  async created() {
    this.setPermisos();
    this.regClientesId = this.$route.params.itemsClientes;
    this.nomCliente = this.regClientesId.clienteNombre;
    this.idReg = this.regClientesId.clienteId;
    this.loadConexiones();
  },
  methods: {
    ...mapActions({
      getConexion: "clientesData/getConexion",
      deleteConexion: "clientesData/deleteConexion",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.modules.adminSistema.permissions.NUEVA_CONEXION_RDP:
            this.canCreateConexion = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.EDIT_CONEXION_RDP:
            this.canEditConexion = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.DELETE_CONEXION_RDP:
            this.canDeleteConexion = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.CONECTAR_RDP:
            this.canConectar = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.VER_CONEXION_RDP:
            this.canVerConectados = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalUsers = true;
      this.registrosParams = item;
    },
    closeAndReload() {
      this.openModalUsers = false;
      this.loadConexiones();
    },
    openModalConect(item) {
      this.openModalConec = true;
      this.registrosParams = item;
    },
    closeAndReloadConect() {
      this.openModalConec = false;
      this.loadConexiones();
    },
    async loadConexiones() {
      this.loading = true;
      const response = await this.getConexion(this.idReg);
      this.itemsDatosConexion = response;
      this.loading = false;
    },
    deleteConec(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.id;
    },
    async confirmDelete() {
      const response = await this.deleteConexion(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConexiones();
      }
    },
  },
};
</script>
<style></style>
